import PropTypes from 'prop-types';
import './ContactModal.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Enerting from '../../assets/enerting-complete-logo.webp';
import emailjs from '@emailjs/browser';

const ContactModal = ({ show, onClose }) => {
   if (!show) return null;

   const handleSubmit = (e) => {
       e.preventDefault();
       
       emailjs.sendForm(
           'service_47tl42g',
           'template_ixj7t0y',
           e.target,
           'xtAVnyuYIWvuFxdyD'
       )
       .then(() => {
           // Notificación de éxito
           const notification = document.createElement('div');
           notification.className = 'success-notification';
           notification.innerHTML = `
               <div class="notification-content">
                   <p>✅ ¡Mensaje enviado con éxito!</p>
                   <small>Nos pondremos en contacto pronto</small>
               </div>
           `;
           document.body.appendChild(notification);
           setTimeout(() => {
               notification.remove();
           }, 3000);
           onClose();
       })
       .catch((error) => {
           console.error('Error:', error);
           // Notificación de error
           const notification = document.createElement('div');
           notification.className = 'error-notification';
           notification.innerHTML = `
               <div class="notification-content">
                   <p>❌ Error al enviar el mensaje</p>
                   <small>Por favor, intente nuevamente</small>
               </div>
           `;
           document.body.appendChild(notification);
           setTimeout(() => {
               notification.remove();
           }, 3000);
       });
   };

   const handleOverlayClick = (e) => {
       if (e.target.className === 'contact-modal-overlay') {
           onClose();
       }
   };

   const handleContentClick = (e) => {
       e.stopPropagation();
   };

   return (
       <div className="contact-modal-overlay" onClick={handleOverlayClick}>
           <div className="contact-modal-content" onClick={handleContentClick}>
               <button className="modal-close-button" onClick={onClose}>
                   <i className="fas fa-times"></i>
               </button>
               <div className="contact-modal-left">
                   <img
                       src={Enerting}
                       alt="Enerting Logo"
                       className="contact-modal-logo"
                   />
                   <p className="contact-modal-description">
                       Estamos comprometidos con la eficiencia energética, aplicando las
                       más modernas tecnologías en nuestros productos y servicios.
                   </p>

                   <div className="contact-modal-info">
                       <p><i className="fas fa-envelope contact-icon"></i> info@enerting.com</p>
                       <p><i className="fas fa-phone contact-icon"></i> +1 (809) 748 2828</p>
                       <p><i className="fas fa-map-marker-alt contact-icon"></i>Calle Respaldo Robles #4, Suite 1, Edif. Profesional Primavera, La Esperilla, Santo Domingo. República Dominicana.</p>
                   </div>

                   <div className="social-icons d-flex justify-content-center mt-3">
                       <a href="https://www.instagram.com/enertingrd/" target="_blank" rel="noopener noreferrer" className="social-icon mr-3">
                           <i className="fab fa-instagram"></i>
                       </a>
                       <a href="https://www.facebook.com/enertingRD/" target="_blank" rel="noopener noreferrer" className="social-icon">
                           <i className="fab fa-facebook"></i>
                       </a>
                   </div>
               </div>
               <div className="contact-modal-right">
                   <h2 className="contact-modal-title">Contacto</h2>
                   <form onSubmit={handleSubmit} className="contact-modal-form">
                       <div className="contact-form-row">
                           <div className="contact-form-group">
                               <label htmlFor="name">Nombre</label>
                               <input type="text" id="name" name="user_name" placeholder="Escriba aquí" required />
                           </div>
                           <div className="contact-form-group">
                               <label htmlFor="lastName">Apellido</label>
                               <input type="text" id="lastName" name="user_lastname" placeholder="Escriba aquí" required />
                           </div>
                       </div>
                       <div className="contact-form-row">
                           <div className="contact-form-group">
                               <label htmlFor="email">Correo Electrónico</label>
                               <input type="email" id="email" name="user_email" placeholder="Escriba aquí" required />
                           </div>
                           <div className="contact-form-group">
                               <label htmlFor="phone">Número de teléfono</label>
                               <input type="tel" id="phone" name="user_phone" placeholder="Escriba aquí" />
                           </div>
                       </div>
                       <div className="contact-form-group">
                           <label htmlFor="message">Mensaje</label>
                           <textarea id="message" name="message" placeholder="Escriba aquí" required></textarea>
                       </div>
                       <button type="submit" className="contact-submit-button">Enviar</button>
                   </form>
               </div>
           </div>
       </div>
   );
};

ContactModal.propTypes = {
   show: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
};

export default ContactModal;